<template>
  <div class="contents dashboard">
    <div class="title flexB">
      <h2>HOME</h2>
    </div>
    <div class="contWrap flex">
      <div class="cont">
        <h4><span class="material-icons">account_circle</span> 전체 가입자</h4>
        <h3>
          {{ totalUser }}
          <span>명</span>
        </h3>
      </div>
      <div class="cont">
        <h4><span class="material-icons">account_circle</span> 일별 가입자</h4>
        <h3>
          {{ dayJoinUser }}
          <span>명</span>
        </h3>
      </div>
      <div class="cont">
        <h4><span class="material-icons">account_circle</span> 전체 설문 수</h4>
        <h3>
          {{ totalFunding }}
          <span>명</span>
        </h3>
      </div>
      <div class="cont">
        <h4><span class="material-icons">account_circle</span> 진행중인 수</h4>
        <h3>
          {{ liveFunding }}
          <span>명</span>
        </h3>
      </div>
    </div>
    <div class="contList">
      <h3>
        설문별 참여자 수 현황
        <span>{{ moment().format("YYYY.MM.DD") }} 기준</span>
      </h3>
      <div class="cont" v-for="(data, i) in postList" :key="i">
        <router-link :to="`surveys/detail?id=${data._id}`">
          <span
            v-if="
              moment(data.endAt).add(1, 'd').format('YYYY.MM.DD') >
              moment().format('YYYY.MM.DD')
            "
          >
            {{ moment(data.startAt).format("YYYY.MM.DD") }} ~
            {{ moment(data.endAt).format("YYYY.MM.DD") }}
          </span>
          <span v-else> 설문마감 </span>
          <h4>{{ data.title }}</h4>
          <p>
            <span>설문금액</span>
            {{ numberFormat(data.totalAmount) }}
            <span>원</span>
          </p>
        </router-link>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchPostList } from "@/api/post";
import { format } from "@/mixins/format";
import { fetchDashboardList } from "@/api/admin";
export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      total: 0,
      currentPage: 1,
      dayJoinUser: 0,
      liveFunding: 0,
      totalFunding: 0,
      totalUser: 0,
      postList: [],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 0);
  },
  mounted() {
    this.getDashboardList();
    this.getPostList();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDashboardList();
    },
    getDashboardList() {
      fetchDashboardList().then((res) => {
        if (res.data.status == 200) {
          this.dayJoinUser = res.data.dayJoinUser;
          this.liveFunding = res.data.liveFunding;
          this.totalFunding = res.data.totalFunding;
          this.totalUser = res.data.totalUser;
        } else {
          return alert(res.data.message);
        }
      });
    },
    getPostList() {
      let params = {
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        category: this.category,
        sort: "latest",
      };
      fetchPostList(params).then((res) => {
        if (res.data.status == 200) {
          this.postList = res.data.postList;
          this.total = res.data.total;
          this.currentPage = res.data.page;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
